const SignageReducer = calculatorData => {
  const { signage_number_of_lines, signage_length_per_line } = calculatorData

  return {
    signage_floor_markers_total:
      Math.ceil(signage_number_of_lines * (signage_length_per_line / 6)) || 0,
    signage_signage_total:
      Math.ceil(signage_number_of_lines * (signage_length_per_line / 6)) || 0,
    signage_rope_total:
      Math.ceil(signage_number_of_lines * (signage_length_per_line / 6)) || 0,
  }
}

export default SignageReducer
