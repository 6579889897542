// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-calculator-disinfectants-js": () => import("./../../../src/pages/calculator/disinfectants.js" /* webpackChunkName: "component---src-pages-calculator-disinfectants-js" */),
  "component---src-pages-calculator-disposable-gloves-js": () => import("./../../../src/pages/calculator/disposable_gloves.js" /* webpackChunkName: "component---src-pages-calculator-disposable-gloves-js" */),
  "component---src-pages-calculator-face-masks-js": () => import("./../../../src/pages/calculator/face_masks.js" /* webpackChunkName: "component---src-pages-calculator-face-masks-js" */),
  "component---src-pages-calculator-face-shields-js": () => import("./../../../src/pages/calculator/face_shields.js" /* webpackChunkName: "component---src-pages-calculator-face-shields-js" */),
  "component---src-pages-calculator-hand-sanitizer-js": () => import("./../../../src/pages/calculator/hand_sanitizer.js" /* webpackChunkName: "component---src-pages-calculator-hand-sanitizer-js" */),
  "component---src-pages-calculator-impermeable-dividers-js": () => import("./../../../src/pages/calculator/impermeable_dividers.js" /* webpackChunkName: "component---src-pages-calculator-impermeable-dividers-js" */),
  "component---src-pages-calculator-index-js": () => import("./../../../src/pages/calculator/index.js" /* webpackChunkName: "component---src-pages-calculator-index-js" */),
  "component---src-pages-calculator-shopping-list-js": () => import("./../../../src/pages/calculator/shopping_list.js" /* webpackChunkName: "component---src-pages-calculator-shopping-list-js" */),
  "component---src-pages-calculator-signage-js": () => import("./../../../src/pages/calculator/signage.js" /* webpackChunkName: "component---src-pages-calculator-signage-js" */),
  "component---src-pages-calculator-start-js": () => import("./../../../src/pages/calculator/start.js" /* webpackChunkName: "component---src-pages-calculator-start-js" */),
  "component---src-pages-faqs-disinfectants-js": () => import("./../../../src/pages/faqs/disinfectants.js" /* webpackChunkName: "component---src-pages-faqs-disinfectants-js" */),
  "component---src-pages-faqs-disposable-gloves-js": () => import("./../../../src/pages/faqs/disposable_gloves.js" /* webpackChunkName: "component---src-pages-faqs-disposable-gloves-js" */),
  "component---src-pages-faqs-face-masks-js": () => import("./../../../src/pages/faqs/face_masks.js" /* webpackChunkName: "component---src-pages-faqs-face-masks-js" */),
  "component---src-pages-faqs-face-shields-js": () => import("./../../../src/pages/faqs/face_shields.js" /* webpackChunkName: "component---src-pages-faqs-face-shields-js" */),
  "component---src-pages-faqs-hand-sanitizer-js": () => import("./../../../src/pages/faqs/hand_sanitizer.js" /* webpackChunkName: "component---src-pages-faqs-hand-sanitizer-js" */),
  "component---src-pages-faqs-impermeable-dividers-js": () => import("./../../../src/pages/faqs/impermeable_dividers.js" /* webpackChunkName: "component---src-pages-faqs-impermeable-dividers-js" */),
  "component---src-pages-faqs-signage-js": () => import("./../../../src/pages/faqs/signage.js" /* webpackChunkName: "component---src-pages-faqs-signage-js" */),
  "component---src-pages-index-es-js": () => import("./../../../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ppe-needs-js": () => import("./../../../src/pages/ppe_needs.js" /* webpackChunkName: "component---src-pages-ppe-needs-js" */)
}

