import logger from "./logger"

const log = logger.extend("multipliers")

export const HOUR = "hour"
export const DAY = "day"
export const WEEK = "week"
export const MONTH = "month"

const HOURS_PER_DAY = 24

// Returns multiplier based on the timeUnit (rate)
// If rate is by HOUR, return number of daysPerWeek * 24 * number of weeks
// If rate is by DAY, return the number of daysPerWeek * number of weeks
// If rate is by WEEK, return the number of weeks
// If rate is by MONTH, return the number of weeks divided by 4 (approx)
// Take this multiplier and multiply by the rate to get the total.
export const getMultiplier = (
  timeUnit = DAY,
  daysPerWeekOpen = 0,
  weeksToPlanFor = 0
) => {
  if (!timeUnit) {
    log("No timeUnit passed")
    return 0
  }

  log(
    `${timeUnit} passed. Days per week open: ${daysPerWeekOpen}. Weeks to plan for: ${weeksToPlanFor}`
  )

  if (typeof weeksToPlanFor !== "number") {
    log("Non-number passed for weeksToPlanFor", weeksToPlanFor)
    weeksToPlanFor = Number(weeksToPlanFor)
  }

  if (Number.isNaN(weeksToPlanFor)) {
    throw new Error(`weeksToPlanFor is NaN`)
  }

  if (timeUnit === HOUR) {
    log("Hour passed", daysPerWeekOpen, HOURS_PER_DAY, weeksToPlanFor)
    return daysPerWeekOpen * HOURS_PER_DAY * weeksToPlanFor
  }

  if (timeUnit === DAY) {
    return daysPerWeekOpen * weeksToPlanFor
  }

  if (timeUnit === WEEK) {
    return weeksToPlanFor
  }

  if (timeUnit === MONTH) {
    return weeksToPlanFor / 4
  }

  throw new Error(`Invalid timeUnit passed: ${timeUnit}`)
}
