const FaceShieldsReducer = calculatorData => {
  const {
    number_of_employees,
    face_shields_per_employee,
    goggles_per_employee,
  } = calculatorData

  return {
    face_shields_per_employee_total:
      face_shields_per_employee * number_of_employees || 0,
    goggles_per_employee_total: goggles_per_employee * number_of_employees || 0,
  }
}

export default FaceShieldsReducer
