import { getMultiplier, WEEK } from "../lib/multipliers"

const DisposableGlovesReducer = calculatorData => {
  const {
    number_of_days_per_week_open,
    number_of_weeks_to_plan_for,
    number_of_employees,
    number_of_customers_per_week,
    disposable_gloves_time_period,
    pairs_of_nitrile_gloves_per_employee,
    pairs_of_rubber_gloves_per_employee,
    pairs_of_polychloroprene_gloves_per_employee,
    pairs_of_vinyl_gloves_per_employee,

    pairs_of_nitrile_gloves_per_customer,
    pairs_of_rubber_gloves_per_customer,
    pairs_of_polychloroprene_gloves_per_customer,
    pairs_of_vinyl_gloves_per_customer,
  } = calculatorData

  const employeeMultiplier = getMultiplier(
    disposable_gloves_time_period,
    number_of_days_per_week_open,
    number_of_weeks_to_plan_for
  )
  const customerMultiplier = getMultiplier(
    WEEK,
    number_of_days_per_week_open,
    number_of_weeks_to_plan_for
  )

  return {
    nitrile_gloves_total:
      2 *
      ((Math.ceil(employeeMultiplier * pairs_of_nitrile_gloves_per_employee) *
        number_of_employees || 0) +
        (Math.ceil(customerMultiplier * pairs_of_nitrile_gloves_per_customer) *
          number_of_customers_per_week || 0)),
    rubber_gloves_total:
      2 *
      ((Math.ceil(employeeMultiplier * pairs_of_rubber_gloves_per_employee) *
        number_of_employees || 0) +
        (Math.ceil(customerMultiplier * pairs_of_rubber_gloves_per_customer) *
          number_of_customers_per_week || 0)),
    polychloroprene_gloves_total:
      2 *
      ((Math.ceil(
        employeeMultiplier * pairs_of_polychloroprene_gloves_per_employee
      ) * number_of_employees || 0) +
        (Math.ceil(
          customerMultiplier * pairs_of_polychloroprene_gloves_per_customer
        ) * number_of_customers_per_week || 0)),
    vinyl_gloves_total:
      2 *
      ((Math.ceil(employeeMultiplier * pairs_of_vinyl_gloves_per_employee) *
        number_of_employees || 0) +
        (Math.ceil(customerMultiplier * pairs_of_vinyl_gloves_per_customer) *
          number_of_customers_per_week || 0)),
  }
}

export default DisposableGlovesReducer
