import React, { createContext, useEffect, useReducer, useState } from "react"
import JsonUrl from "json-url/dist/node"
import {
  getCalculatorData,
  getSectionStatus,
  resetCalculatorData,
  resetSectionStatus,
} from "../lib/store"
import {
  FaceMasksReducer,
  FaceShieldsReducer,
  DisposableGlovesReducer,
  DisinfectantsReducer,
  HandSanitizerReducer,
  ImpermeableDividersReducer,
  SignageReducer,
} from "../reducers"
import logger from "../lib/logger"
import { HOUR } from "../lib/multipliers"
import generateDataUrl from "../lib/generate-data-url"
import { CODEC } from "../lib/generate-data-url"

const log = logger.extend("reducer")
const codec = JsonUrl(CODEC)

const CALCULATOR_DATA_DEFAULT = {
  disinfectants_wipes_time_period: HOUR,
  disinfectants_wipes_per_container: 50,
  disinfectants_spray_bottles_time_period: HOUR,
  disinfectants_liquid_cleaners_time_period: HOUR,
  disinfectants_sprays_bottle_size: "16oz",
  disinfectants_liquid_cleaners_bottle_size: "24oz",

  impermeable_dividers_preferred_material: "plexiglass",
}

const totalsReducer = (state, action) => {
  log(action)
  const { type, key, value } = action
  let newData = state

  switch (type) {
    case "setValue":
      newData = {
        ...state,
        [key]: value,
      }
      break
    case "reset":
      newData = {
        ...value,
      }
      break
    default:
      log("No type passed in action")
  }

  const result = {
    ...newData,
    ...FaceMasksReducer(newData),
    ...FaceShieldsReducer(newData),
    ...DisposableGlovesReducer(newData),
    ...DisinfectantsReducer(newData),
    ...HandSanitizerReducer(newData),
    ...ImpermeableDividersReducer(newData),
    ...SignageReducer(newData),
  }
  log("New state", result)
  return result
}

const sectionStatusReducer = (state, action) => {
  const { type, key, value } = action
  const newState = { ...state }

  switch (type) {
    case "set":
      newState[key] = value
      break
    default:
  }

  return newState
}

const setBase64UrlParam = async function (key, value) {
  if (!window?.history?.replaceState) {
    return
  }

  const url = await generateDataUrl(key, value)

  window.history.replaceState(null, "", url)
}

export const CalculatorContext = createContext({})

export const CalculatorProvider = ({ children }) => {
  const [ready, setReady] = useState(false)
  const [calculatorData, dispatchCalculatorData] = useReducer(totalsReducer, {
    ...CALCULATOR_DATA_DEFAULT,
    ...(getCalculatorData() || {}),
  })
  const [sectionStatus, dispatchSectionStatus] = useReducer(
    sectionStatusReducer,
    getSectionStatus() || {}
  )

  useEffect(() => {
    async function setDataFromUrl() {
      if (!window) {
        return
      }
      const { data } = window.location.search
        .replace("?", "")
        .split("&")
        .reduce((result, param) => {
          const parts = param.split("=")
          result[parts[0]] = parts[1]
          return result
        }, {})

      try {
        const dataFromUrl = data ? await codec.decompress(data) : ""
        log("Data from URL", dataFromUrl)
        if (dataFromUrl) {
          dispatchCalculatorData({
            type: "reset",
            value: JSON.parse(dataFromUrl),
          })
        }
      } catch (e) {
        log("Error parsing data string", e)
      }

      setReady(true)
    }
    setDataFromUrl()
  }, [])

  useEffect(() => {
    resetCalculatorData(calculatorData)
    setBase64UrlParam("data", JSON.stringify(calculatorData))
  }, [calculatorData])

  useEffect(() => {
    resetSectionStatus(sectionStatus)
  }, [sectionStatus])

  return (
    <CalculatorContext.Provider
      value={{
        calculatorData,
        sectionStatus,
        dispatchCalculatorData,
        dispatchSectionStatus,
        ready,
      }}
    >
      {children}
    </CalculatorContext.Provider>
  )
}
