import { getMultiplier } from "../lib/multipliers"

const DisinfectantsReducer = calculatorData => {
  const {
    number_of_days_per_week_open,
    number_of_weeks_to_plan_for,
    disinfectants_time_period,
    disinfectants_wipes_per_time_period,
    disinfectants_wipes_per_container,
    disinfectants_spray_bottles_per_time_period,
    disinfectants_liquid_cleaners_per_time_period,
  } = calculatorData

  const multiplier = getMultiplier(
    disinfectants_time_period,
    number_of_days_per_week_open,
    number_of_weeks_to_plan_for
  )

  return {
    disinfectants_wipes_containers_total:
      Math.ceil(
        (multiplier * disinfectants_wipes_per_time_period) /
          disinfectants_wipes_per_container
      ) || 0,
    disinfectants_spray_bottles_total:
      Math.ceil(multiplier * disinfectants_spray_bottles_per_time_period) || 0,
    disinfectants_liquid_cleaners_total:
      Math.ceil(multiplier * disinfectants_liquid_cleaners_per_time_period) ||
      0,
  }
}

export default DisinfectantsReducer
