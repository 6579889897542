import { getMultiplier } from "../lib/multipliers"

const FaceMasksReducer = calculatorData => {
  const {
    number_of_days_per_week_open,
    number_of_weeks_to_plan_for,
    number_of_employees,
    face_masks_time_period,
    cloth_masks_per_employee,
    face_masks_replace_cloth_masks_in_weeks,
    disposable_masks_per_employee,
    disposable_masks_for_all_customers,
    x95_masks_per_employee,
  } = calculatorData

  const multiplier = getMultiplier(
    face_masks_time_period,
    number_of_days_per_week_open,
    number_of_weeks_to_plan_for
  )

  const clothMaskCycles = Math.max(
    Math.ceil(
      number_of_weeks_to_plan_for /
        (face_masks_replace_cloth_masks_in_weeks || 1)
    ),
    1
  )

  return {
    cloth_masks_per_employee_total:
      Math.ceil(
        cloth_masks_per_employee * number_of_employees * clothMaskCycles
      ) || 0,
    disposable_masks_per_employee_total:
      (disposable_masks_for_all_customers || 0) +
      (Math.ceil(multiplier * disposable_masks_per_employee) *
        number_of_employees || 0),
    x95_masks_per_employee_total:
      Math.ceil(multiplier * x95_masks_per_employee) * number_of_employees || 0,
  }
}

export default FaceMasksReducer
