import { getMultiplier, WEEK } from "../lib/multipliers"

// Estimate
const OZ_PER_USE = 0.006

const usesToOz = uses => {
  return uses * OZ_PER_USE
}

const HandSanitizerReducer = calculatorData => {
  const {
    number_of_customers_per_week,
    number_of_days_per_week_open,
    number_of_weeks_to_plan_for,
    number_of_employees,
    hand_sanitizer_uses_per_customer,
    hand_sanitizer_uses_per_employee,
  } = calculatorData

  const multiplier = getMultiplier(
    WEEK,
    number_of_days_per_week_open,
    number_of_weeks_to_plan_for
  )

  return {
    hand_sanitizer_customer_total_oz:
      Math.ceil(
        number_of_weeks_to_plan_for *
          number_of_customers_per_week *
          usesToOz(hand_sanitizer_uses_per_customer)
      ) || 0,
    hand_sanitizer_employee_total_oz:
      Math.ceil(
        multiplier *
          number_of_employees *
          usesToOz(hand_sanitizer_uses_per_employee)
      ) || 0,
  }
}

export default HandSanitizerReducer
