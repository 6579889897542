import store from "store2"
import logger from "./logger"

const log = logger.extend("store")
const CALCULATOR_NAMESPACE = "PPE_CALCULATOR"

const SECTION_STATUS_KEY = "sectionStatus"
const CALCULATOR_DATA_KEY = "calculatorData"

function generateKey(base, path) {
  return path ? `${base}.${path}` : base
}

export const calculatorStore = store.namespace(CALCULATOR_NAMESPACE)

export function getCalculatorData(key) {
  log("getCalculatorData", key)
  return calculatorStore.get(generateKey(CALCULATOR_DATA_KEY, key), undefined)
}

export function setCalculatorData(key, value) {
  log("setCalculatorData", key, value)
  const data = {
    ...calculatorStore.get(CALCULATOR_DATA_KEY),
    [key]: value,
  }
  return calculatorStore.set(CALCULATOR_DATA_KEY, data)
}

export function resetCalculatorData(value) {
  log("resetCalculatorData", value)
  return calculatorStore.set(CALCULATOR_DATA_KEY, value)
}

export function getSectionStatus(section) {
  return calculatorStore.get(
    generateKey(SECTION_STATUS_KEY, section),
    undefined
  )
}

export function setSectionStatus(section, status) {
  const data = {
    ...calculatorStore.get(SECTION_STATUS_KEY),
    [section]: status,
  }
  return calculatorStore.set(SECTION_STATUS_KEY, data)
}

export function resetSectionStatus(value) {
  return calculatorStore.set(SECTION_STATUS_KEY, value)
}
