const ImpermeableDividersReducer = calculatorData => {
  const {
    impermeable_dividers_number_of_locations,
    impermeable_dividers_dividers_per_location,
  } = calculatorData

  return {
    impermeable_dividers_total:
      impermeable_dividers_dividers_per_location *
        impermeable_dividers_number_of_locations || 0,
  }
}

export default ImpermeableDividersReducer
