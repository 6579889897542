/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"
import "./src/css/index.css"
import logger from "./src/lib/logger"
const { CalculatorProvider } = require("./src/providers/calculator-provider")

const log = logger.extend("gatsby-browser")

const isInViewport = el => {
  const { top, left, bottom, right } = el.getBoundingClientRect()
  return (
    top >= 0 &&
    left >= 0 &&
    bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export const shouldUpdateScroll = ({
  routerProps: {
    location: { state },
  },
}) => {
  const { updateScroll, anchor } = state || {}

  if (anchor) {
    const el =
      document.querySelector(`[name="${anchor}"]`) ||
      document.querySelector(`#${anchor}`)

    log(`Anchor element found %O`, el)

    if (el && !isInViewport(el)) {
      log(`Anchor not in viewport, scrolling`)
      el.scrollIntoView({ behavior: "smooth" })
    }
  }

  if (typeof updateScroll === "boolean") {
    log("Explicit updateScroll passed", updateScroll)
    return updateScroll
  }

  return true
}

export const wrapRootElement = ({ element }) => {
  return <CalculatorProvider>{element}</CalculatorProvider>
}
