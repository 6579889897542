import qs from "qs"
import JsonUrl from "json-url/dist/node"

export const CODEC = "lzstring"

const codec = JsonUrl(CODEC)

const generateDataUrl = async (key, value) => {
  const encodedValue = await codec.compress(value)

  const params = {
    ...qs.parse(window.location.search.replace("?", "")),
    [key]: encodedValue,
  }

  const url = window.location.href.split("?")[0]
  const queryString = qs.stringify(params)

  return `${url}?${queryString}`
}

export default generateDataUrl
